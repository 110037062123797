.lg-css3.lg-zoom-in .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3);
}

.lg-css3.lg-zoom-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3);
}

.lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-lollipop-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5);
}

.lg-css3.lg-lollipop-rev .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-next-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-ver-cross .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -o-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
}

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -o-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
}

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-lollipop .lg-item {
  opacity: 0;
}

.lg-css3.lg-lollipop .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-lollipop .lg-item.lg-next-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5);
}

.lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-lollipop .lg-item.lg-prev-slide, .lg-css3.lg-lollipop .lg-item.lg-next-slide, .lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-ver-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -o-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
}

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -o-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
}

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-rotate-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.lg-css3.lg-rotate-rev .lg-item.lg-next-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.lg-css3.lg-rotate-rev .lg-item.lg-current {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}

.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide, .lg-css3.lg-rotate-rev .lg-item.lg-next-slide, .lg-css3.lg-rotate-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-ver .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -o-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
}

.lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -o-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
}

.lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-rotate .lg-item {
  opacity: 0;
}

.lg-css3.lg-rotate .lg-item.lg-prev-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.lg-css3.lg-rotate .lg-item.lg-next-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.lg-css3.lg-rotate .lg-item.lg-current {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}

.lg-css3.lg-rotate .lg-item.lg-prev-slide, .lg-css3.lg-rotate .lg-item.lg-next-slide, .lg-css3.lg-rotate .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew .lg-item.lg-prev-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
}

.lg-css3.lg-slide-skew .lg-item.lg-next-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
}

.lg-css3.lg-slide-skew .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew .lg-item.lg-next-slide, .lg-css3.lg-slide-skew .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-scale-up .lg-item {
  opacity: 0;
}

.lg-css3.lg-scale-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
}

.lg-css3.lg-scale-up .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
}

.lg-css3.lg-scale-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-scale-up .lg-item.lg-prev-slide, .lg-css3.lg-scale-up .lg-item.lg-next-slide, .lg-css3.lg-scale-up .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-vertical-growth .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
}

.lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
}

.lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-circular-vertical .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
}

.lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
}

.lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-vertical .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.lg-css3.lg-slide-vertical .lg-item.lg-next-slide {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-circular .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-circular .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide-circular .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.lg-css3.lg-slide-circular .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide-circular .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular .lg-item.lg-next-slide, .lg-css3.lg-slide-circular .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-soft-zoom .lg-item {
  opacity: 0;
}

.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.lg-css3.lg-soft-zoom .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}

.lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide, .lg-css3.lg-soft-zoom .lg-item.lg-next-slide, .lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-skew-cross-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-tube .lg-item {
  opacity: 0;
}

.lg-css3.lg-tube .lg-item.lg-prev-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
}

.lg-css3.lg-tube .lg-item.lg-next-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
}

.lg-css3.lg-tube .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-tube .lg-item.lg-prev-slide, .lg-css3.lg-tube .lg-item.lg-next-slide, .lg-css3.lg-tube .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-cross .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-zoom-in-big .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}

.lg-css3.lg-zoom-in-big .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}

.lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-skew-only-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg);
}

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg);
}

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-zoom-in-out .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}

.lg-css3.lg-zoom-in-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg);
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg);
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide-skew-only-y .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg);
}

.lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg);
}

.lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}

.lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-zoom-out-big .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.lg-css3.lg-zoom-out-big .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-skew-only .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg);
}

.lg-css3.lg-slide-skew-only .lg-item.lg-next-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg);
}

.lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}

.lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-zoom-out-in .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.lg-css3.lg-zoom-out-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}

.lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-skew-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
}

.lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-zoom-out .lg-item {
  opacity: 0;
}

.lg-css3.lg-zoom-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7);
}

.lg-css3.lg-zoom-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7);
}

.lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-css3.lg-zoom-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -o-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -o-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
